"use client";
// Core
import type { PropsWithChildren } from "react";
import cx from "classnames";

// Components
import { Layout } from "../../ui/Layout";
import { LayoutContainer } from "../layout-container";

// Utils
import st from "./styles.module.css";

type LayoutContentProps = PropsWithChildren & {
  testId?: string;
  containerType?: "full" | "max";
  noGutters?: boolean;
  hasPrivate?: boolean;
};

export const LayoutContent = (props: LayoutContentProps) => {
  const {
    children = null,
    containerType,
    noGutters,
    hasPrivate,
    testId = "layout-content",
  } = props;
  const containerStyles = cx(st.inner, {
    [st["no-gutters"]]: !!noGutters,
    [st.private]: !!hasPrivate,
  });

  return (
    <Layout.Content className={st.main} data-testid={testId}>
      <LayoutContainer type={containerType}>
        <div className={containerStyles}>{children}</div>
      </LayoutContainer>
    </Layout.Content>
  );
};
